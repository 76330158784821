<template>
  <div>
    <FimButton
      :id="btnId"
      :disabled="!isEnabled"
      :type="btnType"
      :class="btnClass"
      :is-small="btnIsSmall"
      data-testid="virtual-try-on-button"
      @click="onClick"
    >
      {{ buttonLabel }}
      <template v-if="btnIcon" #iconAfter>
        <component :is="btnIcon" class="size-6" />
      </template>
    </FimButton>
    <Teleport to="#rx-pd-tool-portal">
      <div
        class="top:0 fixed inset-0 z-50 flex flex-col bg-black/50 transition-opacity duration-300"
        :class="[
          isModalOpen
            ? 'pointer-events-auto bottom-0 right-0 size-full opacity-100'
            : 'pointer-events-none size-0 overflow-hidden opacity-0',
        ]"
      >
        <RxHeader class="z-50 shrink-0 grow-0" />
        <div class="mx-auto size-full max-w-[90rem] md:p-10">
          <iframe
            v-if="isModalOpen"
            class="bg-white"
            :src="src"
            frameborder="0"
            width="100%"
            height="100%"
            allow="camera; clipboard-write"
          ></iframe>
        </div>
      </div>
    </Teleport>
  </div>
</template>
<script setup lang="ts">
import { useFeatureToggles } from '~/composables/useFeatureToggles'

import useUiState from '~/composables/ui/useUiState'
import {
  type SstCookie,
  SERVER_SIDE_TRACKING_COOKIE_NAME,
} from '~/modules/serverSideTracking/runtime/helper/cookieHelper'
import type { ButtonType } from '#imports'
const $i18n = useI18n()

const props = defineProps({
  btnId: {
    type: String as PropType<string>,
    required: true,
  },
  btnClass: {
    type: String as PropType<string>,
    default: '',
  },
  btnType: {
    type: String as PropType<ButtonType>,
    default: 'secondary',
  },
  btnIcon: {
    type: String as PropType<string>,
    default: '',
  },
  btnIsSmall: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  btnLabel: {
    type: String as PropType<string>,
    default: '',
  },
})

const emit = defineEmits(['pd:change', 'faceWidth:change'])

const $currentShop = useCurrentShop()
const { trackButtonClick, trackPdScan } = await useTrackingEvents()
const { isPdToolEnabled } = await useFeatureToggles()
const { isModalOpen, setModalState } = useUiState()
const language = getIsoLanguage($currentShop.value.locale)
const locale = getNormalizedLocale($currentShop.value.locale)
const shopId = $currentShop.value.shopId

const onClick = (event: Event) => {
  setModalState(true)
  const element = event?.target as HTMLButtonElement
  trackButtonClick(element)
}

let cleanup: ReturnType<typeof useEventListener>
onMounted(() => {
  cleanup = useEventListener('message', (event: MessageEvent) => {
    if (event.data === 'iframe-pdscan-canceled') {
      setModalState(false)
      return
    }

    if (event.data.message === 'iframe-pdscan-closed') {
      setModalState(false)
      // get the pd values in mm or faceWidth and close the iFrame
      emit('pd:change', event.data.pd)
      emit('faceWidth:change', event.data.faceWidth)
    }

    if (event.data.message === 'iframe-track-event') {
      trackPdScan(event)
    }
  })
})

onBeforeUnmount(() => {
  if (cleanup) {
    cleanup()
  }
})

const seidCookie = useCookie<SstCookie>(SERVER_SIDE_TRACKING_COOKIE_NAME)

const src = computed(() => {
  const url = new URL('https://pdscan.focus.measure.fielmann.cloud')
  url.searchParams.set('language', language)
  url.searchParams.set('locale', locale)
  url.searchParams.set('shop-id', shopId.toString())

  // Guard the window access with process.client to prevent SSR errors
  if (import.meta.client && window.location.href) {
    url.searchParams.set('referrer-url', window.location.href)
  }

  if (seidCookie.value && seidCookie.value?._seid) {
    url.searchParams.set('user-id', seidCookie.value._seid)
  }

  return url.toString()
})

const isEnabled = isPdToolEnabled()

const buttonLabel = computed(
  () => props.btnLabel || $i18n.t('rx.prescription.pd.toolButtonLabel'),
)
</script>
