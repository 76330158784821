<template>
  <div
    class="sticky top-0 z-[60] w-full border-b border-shade-300 bg-white"
    :class="{ 'shadow-none': hideShadow }"
  >
    <div
      class="mx-auto flex h-12 w-full max-w-7xl items-center justify-between gap-8 px-4 md:h-16"
    >
      <LogoSvg
        data-testid="rx-header-logo"
        class="cursor-pointer"
        @click="emit('click:logo')"
      />
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps({
  hideShadow: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const emit = defineEmits(['click:logo'])
</script>
