// import { IncomingHttpHeaders } from 'http'
import { getUnixTimestamp } from './serverSideTrackingHelper'

export const getRandomSeId = () => {
  // seid consists of 10 random numbers and the unix time stamp joined by a dot
  const randomNumbers = Array.from({ length: 10 }, () =>
    Math.floor(Math.random() * 9),
  )
  return `${randomNumbers.join('')}.${getUnixTimestamp()}`
}

export const createServerSideTrackingCookie = () => {
  return {
    _seid: getRandomSeId(),
    _seid_s: 1,
  }
}

export type SstCookie = ReturnType<typeof createServerSideTrackingCookie>

export const SERVER_SIDE_TRACKING_COOKIE_NAME = '_seid'

export const deserializeCookieHeader = (
  headers: any,
): Record<string, string> => {
  return (
    headers.cookie
      ?.split('; ')
      .reduce((acc: { [x: string]: string }, curr: string) => {
        const [key, value] = curr.split(/=(.*)/s)
        acc[key] = decodeURIComponent(value)
        return acc
      }, {}) || {}
  )
}
